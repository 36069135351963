








































import { Component, Vue, Prop } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { FeedbackService } from "@/core/services";

@Component({
  components: {},
})
export default class ProjectFeedbackButtonCpt extends AppVue {
  @Prop({ default: "" }) trackingDescription: string;
  @Prop() showDialog?: boolean;
  comment: string = "";
  // showDialog: boolean = false;
  feedbackAmount = 0;
  loading: boolean = false;
  doAnimate: boolean = false;

  click(v: number) {
    if (this.feedbackAmount >= v) {
      this.feedbackAmount = v - 1;
    } else {
      this.feedbackAmount = v;
    }
  }
  star(i: number) {
    if (this.feedbackAmount < i) {
      return "el-icon-star-on grey";
    } else {
      return "el-icon-star-on yellow";
    }
  }
  handleFeedbackClose() {
    this.raiseCloseEvent();
    this.resetForm();
  }

  raiseCloseEvent() {
    this.$emit("close");
  }

  resetForm() {
    this.comment = "";
    this.feedbackAmount = 0;
    this.loading = false;
  }

  async sendFeedback() {
    this.loading = true;
    try {
      await FeedbackService.sendFeedback({
        rating: this.feedbackAmount,
        description: this.trackingDescription,
        comment: this.comment,
      });
      this.loading = false;
      this.raiseCloseEvent();
      this.resetForm();
      this.$notify({
        title: "Feedback sent!",
        type: "success",
        message: `Thank you for your feedback.`,
      });
    } catch (ex: any) {
      this.loading = false;
      this.$notify({
        title: "Something went wrong",
        type: "error",
        message: `We could not send your feedback`,
      });
    }
  }

  async created() {}

  async mounted() {
    window.setTimeout(() => {
      this.doAnimate = true;
    }, 5000);

    window.setTimeout(() => {
      this.doAnimate = false;
    }, 8000);
  }
}
